import React from 'react';
import './Header.css';
import logo from '../../assests/tajam-header-logo.png';
import reorderIcon from '../../assests/recorderIcon.png';
import savingsIcon from '../../assests/savingIcon.png';
import signInIcon from '../../assests/signIn.png';
import cartIcon from '../../assests/cartIcon.png';
import { useSelector } from 'react-redux';
// import SearchIcon from '@mui/icons-material/Search';
import DepartmentDropdown from './DepartmentDropdown';
import LanguageDropdown from './LanguageDropdown';
import SignInDropdown from './SignInDropdown';
import LocationBar from './Location'; // Import LocationBar component

import headerData from './header.json';

const Header = ({ location, postalCode, onUpdateLocation, onUpdateProducts  }) => {
  const iconSrcMapping = {
    reorderIcon,
    savingsIcon,
    signInIcon,
    cartIcon,
  };
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  return (
    <header className="header">
      <div className="header-top">
        <span className="sale-announcement">
          Experience The Ultimate MEGA SALE On Our ECommerce Site! Discover Unbeatable Discounts Across All Categories, With Up To 80% Off On Top Brands And Best-Selling Items!
        </span>
      </div>

      <div className="header-main">
        <img src={logo} alt={headerData.header.logo.alt} className="logo" />

       
        <DepartmentDropdown />

         {/* Integrate the LocationBar component */}
   <LocationBar className="searching-input"
          location={location}
          postalCode={postalCode}
          onUpdateLocation={onUpdateLocation}
          onUpdateProducts={onUpdateProducts}
        />
       <div className="icons">
  {headerData.header.icons.map((icon, index) => {
    if (icon.component === 'LanguageDropdown') {
      return <LanguageDropdown key={index} alt={icon.alt} />;
    } else if (icon.component === 'SignInDropdown') {
      return (
        <SignInDropdown
          key={index}
          icon={signInIcon}
          label={icon.label}
          link={icon.link} 
          dropdownContent={isAuthenticated ? icon.dropdownContent : null}
        />
      );
    } else {
      return (
        <div className="icon-group" key={index}>
          <img
            src={iconSrcMapping[icon.icon]}
            alt={icon.alt || icon.label}
            className="custom-icon"
          />
          <span className="icon-label">{icon.label}</span>
        </div>
      );
    }
  })}
  <div className="vertical-line"></div>
</div>

      </div>

      {/* <div className="header-bottom">
        <div className="club">
          <span>{headerData.header.clubInfo.title}</span>
          <span className="club-location">{headerData.header.clubInfo.location}</span>
        </div>
        <div className="menu">
          {headerData.header.menu.map((menuItem, index) => {
            if (menuItem.label === 'More') {
              return (
                <div key={index} className="menu-link more">
                  {menuItem.label}
                  {menuItem.submenu && (
                    <div className="submenu">
                      {menuItem.submenu.map((subItem, subIndex) => (
                        <a key={subIndex} href={subItem.href} className="submenu-link">
                          {subItem.label}
                        </a>
                      ))}
                    </div>
                  )}
                </div>
              );
            } else {
              return (
                <div key={index} className="menu-link">
                  <a href={menuItem.href}>{menuItem.label}</a>
                </div>
              );
            }
          })}
        </div>
      </div> */}
    </header>
  );
};

export default Header;
