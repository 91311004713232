import React, { useState } from 'react';
import './ServiceModal.css';
import { useMutation } from '@apollo/client';
import { DELETE_SERVICE, UPDATE_SERVICE } from '../../GraphQL/mutation'; // Import mutations

const ServiceModal = ({ service, onUpdate, onClose, refetchService, businessId }) => {
  const [formData, setFormData] = useState({
    serviceName: service?.serviceName || '',
    description: service?.description || '',
    price: service?.price || '',
    discountedPrice: service?.discountedPrice || '',
    likedCount: service?.likedCount || 0,
    purchasedCount: service?.purchasedCount || 0,
  });

  // Mutation for deleting a service
  const [deleteService] = useMutation(DELETE_SERVICE, {
    onCompleted: () => {
      refetchService().then(() => {
        alert('Service deleted successfully');
        onClose();
      });
    },
    onError: (error) => {
      alert(`Error deleting service: ${error.message}`);
    },
  });

  const [updateService] = useMutation(UPDATE_SERVICE, {
    onCompleted: (data) => {
      console.log("API response from updateService:", data); // Log the response
      alert('Service updated successfully');
      refetchService(); // Optionally refetch the service list
      onClose();
    },
    onError: (error) => {
      console.error("Error updating service:", error); // Log the error
      alert(`Error updating service: ${error.message}`);
    },
  });
  

  const handleDelete = () => {
    deleteService({
      variables: {
        businessId,
        serviceName: service.serviceName,
      },
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleUpdate = () => {
    const input = {
      businessId,
      previousServiceName: service.serviceName,
      service: {
        ...formData,
      },
    };

    console.log("Payload sent to updateService API:", input);


    updateService({
      variables: { input },
    });
  };

  if (!service) return null;

  return (
    <div className="service-modal">
      <button className="closing-btn" onClick={onClose}>
        &times;
      </button>

      <div className="modal-service">
        <img
          src={service.imagePaths?.[0] || 'https://via.placeholder.com/150'}
          alt={service.serviceName}
          className="modal-service-image"
        />

        <div className="modal-fields">
          <label>
            <span>Service Name:</span>
            <input
              type="text"
              name="serviceName"
              value={formData.serviceName}
              onChange={handleInputChange}
            />
          </label>

          <label>
            <span>Description:</span>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleInputChange}
            />
          </label>

          <div className="flexxxx">
          <label>
            <span>Price:</span>
            <input
              type="number"
              name="price"
              value={formData.price}
              onChange={handleInputChange}
            />
          </label>

          <label>
            <span>Discounted Price:</span>
            <input
              type="number"
              name="discountedPrice"
              value={formData.discountedPrice}
              onChange={handleInputChange}
            />
          </label>

          </div>

          <div className="flexxxx">
            <label>
              <span>Liked Count:</span>
              <input
                type="number"
                name="likedCount"
                value={formData.likedCount}
                onChange={handleInputChange}
              />
            </label>

            <label>
              <span>Purchased Count:</span>
              <input
                type="number"
                name="purchasedCount"
                value={formData.purchasedCount}
                onChange={handleInputChange}
              />
            </label>
          </div>
        </div>

        <div className="modal-actions">
          <button className="delete-btn" onClick={handleDelete}>
            Delete
          </button>
          <button className="update-btn" onClick={handleUpdate}>
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default ServiceModal;
