import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import ProductModal from "./ProductModal";
import {
  GET_CATEGORIES_BY_BUSINESS,
  GET_PRODUCTS_BY_CATEGORY_IN_BUSINESS,
  UPDATE_PRODUCT_STATUS,
} from "../../GraphQL/mutation";
import "./AllBusinessProducts.css";

const AllBusinessProducts = () => {
  const businessId = window.location.pathname.split("/").pop();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);

  // Fetch categories
  const { loading: loadingCategories, error: errorCategories, data: categoryData } = useQuery(
    GET_CATEGORIES_BY_BUSINESS,
    {
      variables: { businessId },
      onCompleted: (data) => {
        const categories = data?.getCategoriesByBusiness?.data || [];
        if (categories.length > 0) {
          setSelectedCategory(categories[0]); // Set the first category as default
        }
      },
    }
  );

  // Fetch products
  const { loading: loadingProducts, error: errorProducts, refetch } = useQuery(
    GET_PRODUCTS_BY_CATEGORY_IN_BUSINESS,
    {
      skip: !selectedCategory,
      variables: {
        businessId,
        categoryName: selectedCategory,
        pageNo: 0,
        noOfElements: 10,
      },
      onCompleted: (data) => {
        setProducts(data?.getProductsByCategoryInBusiness?.data?.products || []);
      },
    }
  );

  // Mutation for updating product status
  const [updateProductStatus] = useMutation(UPDATE_PRODUCT_STATUS);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    refetch({
      businessId,
      categoryName: category,
      pageNo: 0,
      noOfElements: 10,
    });
  };

  const handleProductClick = (product) => {
    setSelectedProduct(product);
  };

  const handleCloseModal = () => {
    setSelectedProduct(null);
  };

  const handleUpdateStatus = async (product) => {
    const updatedStatus = product.status === "Available" ? "Unavailable" : "Available";

    // Show loader or updating indicator
    setProducts((prevProducts) =>
      prevProducts.map((p) =>
        p.productName === product.productName && p.categoryName === selectedCategory
          ? { ...p, updating: true }
          : p
      )
    );
console.log(product);
    try {
      const response = await updateProductStatus({
        variables: {
          input: {
            businessId,
            categoryName: selectedCategory,
            productName: product.productName,
            status: updatedStatus,
          },
        },
      });

      const result = response.data.updateProductStatus;

      if (result.status === 200) {
        // Update the product's status in the local state
        setProducts((prevProducts) =>
          prevProducts.map((p) =>
            p.productName === product.productName
              ? { ...p, status: updatedStatus, updating: false }
              : p
          )
        );

        alert("Product status has been successfully updated.");
      } else {
        alert(`Failed to update status: ${result.message}`);
      }
    } catch (error) {
      alert("An error occurred while updating the status.");
      console.error("Error during mutation:", error);
    } finally {
      console.log("after ",product);
      // Remove the updating indicator
      setProducts((prevProducts) =>
        prevProducts.map((p) =>
          p.productName === product.productName
            ? { ...p, updating: false }
            : p
        )
      );
    }
  };

  if (loadingCategories) return <p>Loading categories...</p>;
  if (errorCategories) return <p>Error: {errorCategories.message}</p>;

  const categories = categoryData?.getCategoriesByBusiness?.data || [];

  return (
    <div className="all-business-products">
      {/* Categories */}
      <div className="categories">
        {categories.map((category, index) => (
          <button
            key={index}
            className={`category-btn ${selectedCategory === category ? "active" : ""}`}
            onClick={() => handleCategoryClick(category)}
          >
            {category}
          </button>
        ))}
      </div>

      {/* Products */}
      {loadingProducts ? (
        <p>Loading products...</p>
      ) : errorProducts ? (
        <p>Error loading products: {errorProducts.message}</p>
      ) : (
        <div className="products">
          {products.map((product, index) => (
            <div
              key={index}
              className="product-card"
              onClick={() => handleProductClick(product)}
            >
              <div className="product-image">
                <img
                  src={product.imagePaths?.[0] || "https://via.placeholder.com/150"}
                  alt={product.productName}
                />
              </div>
              <div className="product-details">
                <h3>{product.productName}</h3>
                <p>{product.description}</p>
                <p>Price: PKR {product.price}</p>
                <p>Discounted Price: PKR {product.discountedPrice}</p>
                <p>Status: {product.status}</p>
                <button className="btn"
                  disabled={product.updating}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent modal opening
                    handleUpdateStatus(product);
                  }}
                >
                  {product.updating
                    ? "Updating..."
                    : `Make Product ${product.status === "Available" ? "Unavailable" : "Available"}`}
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Modal */}
      {selectedProduct && (
        <ProductModal
          product={selectedProduct}
          businessId={businessId}
          categoryName={selectedCategory}
          onClose={handleCloseModal}
          refetchProducts={refetch}
        />
      )}
    </div>
  );
};

export default AllBusinessProducts;
