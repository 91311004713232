import React from 'react';
import './Footer.css';
// import fb from '../../assests/fb.png';
// import twitter from '../../assests/twiiter.png';
// import linkedin from '../../assests/linkedin.png';
// import instagram from '../../assests/insta.png';
// import youtube from '../../assests/youtube.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faInstagram, faLinkedinIn, faYoutube } from '@fortawesome/free-brands-svg-icons';
import GooglePlayIcon from '../../assests/playstorebtn.svg';
import AppStoreIcon from '../../assests/appstorebtn.svg';
import customerqr from '../../assests/customerqr.jpg';
import businessqr from '../../assests/businessqr.jpg';

const Footer = () => {
  return (

    <>
    
    <div className="footer">


    <footer className="footer">
     <div className="subscription-row">
      <div className="subscription-field">
        <div className="label">
        <label>Enter your email</label>
       
       <input type="email" placeholder="ex: sam@taijam.com" />
        </div>
      
        <button className="signup-button">Sign up for emails</button>
      </div>
      <div className="subscription-field">
        <div className="label">

        <label>Enter your mobile number</label>
    
    <input type="tel" placeholder="ex: (123) 456-7890" />
        </div>
        <button className="signup-button">Sign up for texts</button>
      </div>
      <p className="subscription-message">
        <b>Sign up for saving events, special offers,
        <br /> and more.</b>
      </p>
    </div>
      <div className="footer-links">
        <div className="link-section">
          <h1>Shipping & Delivery</h1>
          <a href="#">Track Orders</a>
          <a href="/console">Console</a>
          <a href="#">Shipping FAQs</a>
          <a href="#">Pickup</a>
          <a href="#">Same Day Delivery</a>
          <a href="#">Return & Refunds</a>
        </div>
        <div className="link-section">
          <h1>Business</h1>
          <a href="#">Community Recycling</a>
          <a href="#">For Suppliers</a>
          <a href="#">Renew Your Business</a>
          <a href="#">Register Your Business</a>
        </div>
        <div className="link-section">
          <h1>Resources</h1>
          <a href="#">About Us</a>
          <a href="#">Newsroom</a>
          <a href="#">Careers</a>
          <a href="#">Club Directory</a>
          <a href="#">Truckloads</a>
          <a href="#">Exports</a>
        </div>
        <div className="link-section">
          <h1>Help</h1>
          <a href="#">Help Center</a>
          <a href="#">Product Recalls</a>
          <a href="#">My Account</a>
          <a href="#">Affiliate Program</a>
          <a href="#">Advertise With Us</a>
        </div>
        <div className="link-section">
          <h1>Apps</h1>

          <div className="apps">
<div className="up">

<p className='apptags' href="#">Customer App</p>
<p className='apptags' href="#">Business App</p>
</div>
<div className="qr">
<img src={customerqr} className="qr-icon" alt="customer app QR" />
<img src={businessqr} className="qr-icon" alt="business app QR" />

</div>
<div className="app-links">
<img src={GooglePlayIcon} className="store-icon" alt="Google Play" />
<img src={AppStoreIcon} className="store-icon" alt="Google Play" />
          </div>
          </div>
          
         
        </div>
      </div>
      <div className="footer-bottom">
        <div className="social-contacts">
          <p className='social-contacts'><b>Social Contacts</b></p>
          <div className="social-icons">
            <a href="#" aria-label="Facebook">
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
            <a href="#" aria-label="Twitter">
              <FontAwesomeIcon icon={faTwitter} />
            </a>
            <a href="#" aria-label="Instagram">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a href="#" aria-label="LinkedIn">
              <FontAwesomeIcon icon={faLinkedinIn} />
            </a>
            <a href="#" aria-label="YouTube">
              <FontAwesomeIcon icon={faYoutube} />
            </a>
          </div>
        </div>
        <div className="footer-legal">
          <p>Terms and conditions &nbsp; &nbsp; © 2024 taijam. All rights reserved.</p>
        </div>
      </div>
    </footer>
    </div>
    
    </>
  
  );
};

export default Footer;
