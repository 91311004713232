import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../auth/authContext';
import { currentWebInterface } from '../../store/reducers/main';
import { GET_USER_BY_ID } from '../../GraphQL/mutation';
import { FaUser } from 'react-icons/fa';
import './Header.css';
import signInIcon from '../../assests/signIn.png';
import { useLazyQuery } from '@apollo/client';

const SignInDropdown = ({ dropdownContent,link }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuthenticated, logout } = useAuth();
  const [username, setUsername] = useState('');
  const [isHovered, setIsHovered] = useState(false);
  const { webInterface } = useSelector((state) => state.main);
  const [getUserById, { data: userData }] = useLazyQuery(GET_USER_BY_ID);

  // Button text based on the current web interface
  const buttonText =
    webInterface === 'business' ? 'Switch to Business' : 'Switch to Customer';

  const toggleWebInterface = () => {
    const switchedInterface =
      webInterface === 'business' ? 'customer' : 'business';
    dispatch(currentWebInterface(switchedInterface));
    navigate(switchedInterface === 'business' ? '/' : '/business/profile');
  };

  const handleLogout = () => {
    // Remove user data from local storage and logout
    [
      'firstName',
      'lastName',
      'userId',
      'token',
      'userInfo',
      'userData',
    ].forEach((key) => localStorage.removeItem(key));
    logout();
    navigate('/');
  };

  // Fetch username on mount
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) getUserById();
  }, [getUserById]);

  // Set username from API response or local storage
  useEffect(() => {
    if (userData) {
      const fetchedUsername =
        userData.getUserById?.userName?.split('@')[0] || null;
      const userInfo = JSON.parse(localStorage.getItem('userInfo')); // Assuming userInfo is stored as a JSON string

      if (fetchedUsername) {
        setUsername(fetchedUsername);
      } else if (userInfo && userInfo.email) {
        setUsername(userInfo.email.split('@')[0]); // Extract username from email
      }
    }
  }, [userData]);

  return (
    <div
      className="icon-group"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isAuthenticated ? (
        <>
          <FaUser size={20} color="white" className="custom-icon" />
          <span className="icon-label">{username}</span>
        </>
      ) : (
        <>
         <a className="icon-label" href={link}>

         <img src={signInIcon} alt="Sign In" className="custom-icon" />
         <span className="icon-label">Sign In</span>
         </a>
        </>
      )}

      {isHovered && isAuthenticated && dropdownContent?.authenticated && (
        <div className="dropdown-content">
          <div className="left-section">
            {dropdownContent.authenticated.menuItems.map((item, index) => (
              <a key={index} href={item.link} className="menu-item">
                {item.label}
              </a>
            ))}
          </div>
          <div className="right-section">
            <h1 className="list">
              <b>Your Lists</b>
            </h1>
            {dropdownContent.authenticated.yourLists.map((list, index) => (
              <a key={index} href={list.link} className="menu-item">
                {list.label}
              </a>
            ))}
            <br />
            <button className="sign-in" onClick={handleLogout}>
              {dropdownContent.authenticated.logoutButton.label}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SignInDropdown;
