import React from 'react';
import { FaTags, FaShoppingCart, FaRegStar, FaThLarge, FaBox, FaUndo, FaInfoCircle, FaWrench, FaShoppingBag, FaShieldAlt, FaLifeRing } from 'react-icons/fa';
import Footer from '../../components/NewFooter';
import './consolestyles.css';

export const consoleOptions = [
  { title: 'Pricing', link: '/pricing', icon: <FaTags /> },
  { title: 'Shop', link: '/shop', icon: <FaShoppingBag /> },
  { title: 'Best Deals', link: '/best-deals', icon: <FaRegStar /> },
  { title: 'Categories', link: '/shop-all', icon: <FaThLarge /> },
  { title: 'Products', link: '/products', icon: <FaBox /> },
  { title: 'Return Policy', link: '/customer/return-policy', icon: <FaUndo /> },
  { title: 'About Us', link: '/about-us', icon: <FaInfoCircle /> },
  { title: 'Services', link: '/services', icon: <FaWrench /> },
  { title: 'Cart', link: '/cart', icon: <FaShoppingCart /> },
  { title: 'Policy', link: '/customer/return-policy', icon: <FaShieldAlt /> },
  { title: 'Support', link: '/support', icon: <FaLifeRing /> },
];

const ConsolePage = () => {
  return (

    <>
     <div className="console-container">
      <h2 className="console-heading">Quick Access</h2>
      <div className="console-grid">
        {consoleOptions.map((option, index) => (
          <a key={index} href={option.link} className="console-card">
            <div className="console-icon">{option.icon}</div>
            <span className="console-title">{option.title}</span>
          </a>
        ))}
      </div>
    
    </div>
    
    
    <Footer />
    </>
   
  );
};

export default ConsolePage;
