import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { GET_ALL_SERVICES, UPDATE_SERVICE_STATUS } from "../../GraphQL/mutation";
import { useParams } from "react-router-dom";
import "./AllBusinessServices.css";
import ServiceModal from "./ServiceModal"; // Properly import the modal

const AllBusinessServices = () => {
  const { businessId } = useParams(); // Get businessId from URL parameters
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);

  // Fetch services
  const { loading, error, refetch } = useQuery(GET_ALL_SERVICES, {
    variables: {
      businessId, // Use the businessId from the URL
      pageNo: 0,
      noOfElements: 10,
    },
    onCompleted: (data) => {
      setServices(data?.getAllServicesInBusiness?.data?.services || []);
    },
    onError: (err) => {
      console.error("Error fetching services:", err);
    },
  });

  // Mutation for updating service status
  const [updateServiceStatus] = useMutation(UPDATE_SERVICE_STATUS);

  const handleServiceClick = (service) => {
    setSelectedService(service); // Open modal with selected service data
  };

  const handleCloseModal = () => {
    setSelectedService(null); // Close the modal
  };

  const handleUpdateStatus = async (service) => {
    const updatedStatus = service.status === "Available" ? "Unavailable" : "Available";

    // Optimistic UI: Show loader for the specific service
    setServices((prevServices) =>
      prevServices.map((s) =>
        s.serviceName === service.serviceName ? { ...s, updating: true } : s
      )
    );

    try {
      const response = await updateServiceStatus({
        variables: {
          input: {
            businessId,
            serviceName: service.serviceName,
            status: updatedStatus,
          },
        },
      });

      const result = response.data.updateServiceStatus;

      if (result.status === 200) {
        // Update the service status locally
        setServices((prevServices) =>
          prevServices.map((s) =>
            s.serviceName === service.serviceName
              ? { ...s, status: updatedStatus, updating: false }
              : s
          )
        );

        alert("Service status has been successfully updated.");
      } else {
        alert(`Failed to update status: ${result.message}`);
      }
    } catch (error) {
      alert("An error occurred while updating the status.");
      console.error("Error during mutation:", error);
    } finally {
      // Remove updating indicator
      setServices((prevServices) =>
        prevServices.map((s) =>
          s.serviceName === service.serviceName ? { ...s, updating: false } : s
        )
      );
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <>
      <h1 className="service-head">All Services</h1>
      <div className="services-container">
        {services.map((service, index) => (
          <div
            className="service-card"
            key={index}
            onClick={() => handleServiceClick(service)} // Add click handler
          >
            <img
              src={service.listImagePath || "https://via.placeholder.com/150"}
              alt={service.serviceName}
              className="service-image"
            />
            <div className="service-info">
              <h2 className="service-title">{service.serviceName}</h2>
              <p className="service-description">{service.description}</p>
              <p className="service-price">PKR {service.price}</p>
              <p className="service-discounted">
                Discounted Price: PKR {service.discountedPrice}
              </p>
              <p className="service-status">Status: {service.status}</p>
            </div>
            <div className="service-actions">
              <span className="ratings">👍 Likes: {service.likedCount}</span>
              <span className="ratings">🛒 Purchased: {service.purchasedCount}</span>
              {/* <div className="availability">
                {service.availableSlots > 0 ? "Available" : "Unavailable"}
              </div> */}

              <button className="btn"
                disabled={service.updating}
                onClick={(e) => {
                  e.stopPropagation(); // Prevent modal opening
                  handleUpdateStatus(service);
                }}
              >
                {service.updating
                  ? "Updating..."
                  : `Make Service ${service.status === "Available" ? "Unavailable" : "Available"}`}
              </button>
            </div>
          </div>
        ))}

        {/* Modal */}
        {selectedService && (
          <ServiceModal
            service={selectedService}
            businessId={businessId} // Pass businessId
            onClose={handleCloseModal}
            refetchService={refetch} // Pass refetch to the modal
          />
        )}
      </div>
    </>
  );
};

export default AllBusinessServices;
