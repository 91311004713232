import React, { useState } from 'react';
import './ProductModal.css';
import { useMutation } from '@apollo/client';
import { DELETE_PRODUCT, UPDATE_PRODUCT_BY_CATEGORY } from '../../GraphQL/mutation'; // Import both mutations

const ProductModal = ({ product, onUpdate, onClose, refetchProducts, businessId, categoryName }) => {
  const [formData, setFormData] = useState({
    productName: product?.productName || '',
    description: product?.description || '',
    price: product?.price || '',
    discountedPrice: product?.discountedPrice || '',
    likedCount: product?.likedCount || 0,
    purchasedCount: product?.purchasedCount || 0,
  });

  // Mutation for deleting a product
  const [deleteProduct] = useMutation(DELETE_PRODUCT, {
    onCompleted: () => {
      refetchProducts().then(() => {
        alert('Product deleted successfully');
        onClose();
      });
    },
    onError: (error) => {
      alert(`Error deleting product: ${error.message}`);
    },
  });

  // Mutation for updating a product
  const [updateProductByCategory] = useMutation(UPDATE_PRODUCT_BY_CATEGORY, {
    onCompleted: (data) => {
      alert(`Product updated successfully: ${data.updateProductByCategory.message}`);
      refetchProducts(); // Optionally refetch the product list
      onClose();
    },
    onError: (error) => {
      alert(`Error updating product: ${error.message}`);
    },
  });

  const handleDelete = () => {
    deleteProduct({
      variables: {
        businessId,
        categoryName,
        productName: product.productName,
      },
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleUpdate = () => {
    const input = {
      businessId,
      categoryName,
      previousProductName: product.productName,
      product: {
        ...formData,
        quantity: product.quantity || 0, // Add any additional fields required by the API
        status: product.status || 'AVAILABLE', // Default value if not present
      },
    };

    updateProductByCategory({
      variables: { input },
    });
  };

  if (!product) return null;

  return (
    <div className="product-modal">
      <button className="closingggg-btn" onClick={onClose}>
        &times;
      </button>

      <div className="modal-product">
        <img
          src={product.imagePaths?.[0] || 'https://via.placeholder.com/150'}
          alt={product.productName}
          className="modal-product-image"
        />

        <div className="modal-fields">
          <label>
            <span>Product Name:</span>
            <input
              type="text"
              name="productName"
              value={formData.productName}
              onChange={handleInputChange}
            />
          </label>

          <label>
            <span>Description:</span>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleInputChange}
            />
          </label>

          <label>
            <span>Price:</span>
            <input
              type="number"
              name="price"
              value={formData.price}
              onChange={handleInputChange}
            />
          </label>

          <label>
            <span>Discounted Price:</span>
            <input
              type="number"
              name="discountedPrice"
              value={formData.discountedPrice}
              onChange={handleInputChange}
            />
          </label>

          <div className="flexxxx">
            <label>
              <span>Liked Count:</span>
              <input
                type="number"
                name="likedCount"
                value={formData.likedCount}
                onChange={handleInputChange}
              />
            </label>

            <label>
              <span>Purchased Count:</span>
              <input
                type="number"
                name="purchasedCount"
                value={formData.purchasedCount}
                onChange={handleInputChange}
              />
            </label>
          </div>
        </div>

        <div className="modal-actions">
          <button className="delete-btn" onClick={handleDelete}>
            Delete
          </button>
          <button className="update-btn" onClick={handleUpdate}>
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductModal;
